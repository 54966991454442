import React from 'react';
import Nav from '../components/Nav/Nav';

export default function CvPage() {
  return (
    <div>
      <Nav />
    </div>
  );
}
