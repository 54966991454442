import React from 'react';
import { Link } from 'gatsby';
import './Nav.scss';

export default function Nav() {
  return (
    <nav className="navigation">
      <Link className="navigation__grid-item" to="/">
        Artwork
      </Link>
      <Link className="navigation__grid-item" to="/about">
        About Sandor
      </Link>
    </nav>
  );
}
